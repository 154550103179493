html body {
  font-family: 'Inter', sans-serif;
}

html,
#root,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
  font-weight: 400;
}

body,
#root,
.hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#basic-menu .MuiMenu-list {
  padding: 0;

  h4 {
    font-weight: 400;
  }
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

// .zsiq_floatmain.siq_bR {
//   right: 35px;
//   bottom: 35px;
// }

// .BeaconContainer {
//   z-index: 1111;
// }

// @font-face {
//   font-family: "SpaceGroteskBold";
//   src: local("SpaceGroteskBold"),
//     url("./fonts/SpaceGrotesk-Bold.ttf") format("truetype");
//   font-weight: 700;
// }

// @font-face {
//   font-family: "SpaceGroteskLight";
//   src: local("SpaceGroteskLight"),
//     url("./fonts/SpaceGrotesk-Light.ttf") format("truetype");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "SpaceGroteskMedium";
//   src: local("SpaceGroteskMedium"),
//     url("./fonts/SpaceGrotesk-Medium.ttf") format("truetype");
//   font-weight: 500;
// }

// @font-face {
//   font-family: "SpaceGroteskSemiBold";
//   src: local("SpaceGroteskSemiBold"),
//     url("./fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
//   font-weight: 600;
// }

// //TODO: need to remove next 4 font faces and remove imports from all components
// @font-face {
//   font-family: "InterBold";
//   src: local("InterBold"),
//     url("./fonts/Inter-Bold.ttf") format("truetype");
//   font-weight: 700;
// }

// @font-face {
//   font-family: "InterLight";
//   src: local("InterLight"),
//     url("./fonts/Inter-Light.ttf") format("truetype");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "InterMedium";
//   src: local("InterMedium"),
//     url("./fonts/Inter-Medium.ttf") format("truetype");
//   font-weight: 500;
// }

// @font-face {
//   font-family: "InterSemiBold";
//   src: local("InterSemiBold"),
//     url("./fonts/Inter-SemiBold.ttf") format("truetype");
//   font-weight: 600;
// }

// //Do not remove these fonts before font above will not be removed
// @font-face {
//   font-family: "Inter";
//   src: local("InterLight"),
//   url("./fonts/Inter-Light.ttf") format("truetype");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "Inter";
//   src: local("InterMedium"),
//   url("./fonts/Inter-Medium.ttf") format("truetype");
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Inter";
//   src: local("InterMedium"),
//   url("./fonts/Inter-Medium.ttf") format("truetype");
//   font-weight: 500;
// }

// @font-face {
//   font-family: "Inter";
//   src: local("InterSemiBold"),
//   url("./fonts/Inter-SemiBold.ttf") format("truetype");
//   font-weight: 600;
// }

// @font-face {
//   font-family: "Inter";
//   src: local("InterBold"),
//   url("./fonts/Inter-Bold.ttf") format("truetype");
//   font-weight: 700;
// }
